import type { AlertDefinition } from "@/shared/alert/alert.model";

export const ALERT_EVENT_NAME = "APP_ALERT";
export const useAlert = () => {
  const { $emit } = useNuxtApp();

  const showAlert = (definition: AlertDefinition) => {
    $emit(ALERT_EVENT_NAME, definition);
  };

  const showError = (message: string) => {
    showAlert({
      level: "error",
      message,
    });
  };

  const showSuccess = (message: string) => {
    showAlert({
      level: "success",
      message,
    });
  };

  return {
    showError,
    showSuccess,
  };
};
