<template>
  <v-snackbar v-model="showSnackbar" :color="state?.level">
    <p :data-cy-alert="state?.level">
      {{ state?.message }}
    </p>
  </v-snackbar>
</template>

<script lang="ts" setup>
import type { AlertDefinition } from "@/shared/alert/alert.model";
import { ALERT_EVENT_NAME } from "@/shared/alert/composables/alert.hook";

const { $on } = useNuxtApp();

const showSnackbar = ref(false);

const state = ref<AlertDefinition | undefined>();

$on(ALERT_EVENT_NAME, (definition: AlertDefinition) => {
  showSnackbar.value = false;
  state.value = definition;
  showSnackbar.value = true;
});
</script>
