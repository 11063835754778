import type { AppUser } from "@/core/authentication/authentication.model";

export const useMe = () => {
  const supabaseUser = useSupabaseUser();
  const me = computed<AppUser | null>(() => {
    if (!supabaseUser.value) return null;

    return {
      id: supabaseUser.value.id,
      email: supabaseUser.value.email ?? "",
      fullName: supabaseUser.value.user_metadata.full_name,
      avatarUrl: supabaseUser.value.user_metadata.avatar_url,
    };
  });

  const isAuthenticated = computed(() => !!me.value);
  const isAdmin = computed(() => !!supabaseUser.value?.app_metadata.admin);

  return {
    me,
    isAuthenticated,
    isAdmin,
  };
};
