import { defineRule } from "vee-validate";

export default defineNuxtPlugin(() => {
  defineRule("required", (value: string) => {
    if (
      value === null ||
      value === "" ||
      value === undefined ||
      (Array.isArray(value) && value.length === 0)
    )
      return "Field is mandatory";

    return true;
  });

  defineRule("email", (value: string, parameters, context) => {
    if (!value || /^[\w!#$%&'*+./=?^`{|}~-]+@[\d.A-Za-z-]+$/gm.test(value))
      return true;

    return `${context.field} n'est pas un e-mail valide`;
  });

  defineRule("matches", (value: string, parameters, context) => {
    if (!new RegExp((parameters as { pattern: string }).pattern).test(value)) {
      return `${context.field} does not have the right value`;
    }

    return true;
  });

  defineRule(
    "maxLength",
    (value: unknown[] | undefined, parameters, context) => {
      if (!value) return true;

      const { max } = parameters as { max: number };

      if (value.length > max)
        return `${context.field} length must be lower than or equal to ${max}`;

      return true;
    },
  );

  defineRule(
    "gt",
    (value: string | undefined, min: string): boolean | string => {
      if (!value) return true;

      if (value <= min) return `Field must be superior to ${min}`;

      return true;
    },
  );

  defineRule(
    "lt",
    (value: string | undefined, max: string): boolean | string => {
      if (!value) return true;

      if (value > max) return `Field must be inferior to ${max}`;

      return true;
    },
  );
});
