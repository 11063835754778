<template>
  <div>
    <v-app>
      <nuxt-layout>
        <nuxt-page />
      </nuxt-layout>
    </v-app>
  </div>

  <client-only>
    <app-modal-handler />
    <app-alert-handler />
  </client-only>
</template>

<script lang="ts" setup>
useSeoMeta({
  robots: "noindex",
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Pollen Admin` : "Pollen Admin";
  },
});
</script>
