const preventUserScroll = () => {
  document.documentElement.style.paddingRight = `${
    window.innerWidth - document.documentElement.clientWidth
  }px`;
  document.documentElement.classList.add("overflow-hidden");
};

const allowUserScroll = () => {
  document.documentElement.style.paddingRight = "";
  document.documentElement.classList.remove("overflow-hidden");
};

export const useScrollPrevent = () => {
  return {
    preventUserScroll,
    allowUserScroll,
  };
};
