import type { IconProps, VuetifyOptions } from "vuetify";

import { Icon } from "#components";

const aliases = {
  dropdown: "ph:caret-down",
  checkboxOn: "ph:check-square-fill",
  checkboxOff: "ph:square-bold",
};

export type AppIconName = keyof typeof aliases;

export const themeIconsConfiguration: VuetifyOptions["icons"] = {
  defaultSet: "custom",
  aliases,
  sets: {
    custom: {
      component: (properties: IconProps) =>
        h("span", [h(Icon, { name: properties.icon as string })]),
    },
  },
};
