import { useScrollPrevent } from "../../scroll/composables/scroll-prevent.hook";
import type { AppModalId } from "../modal.registry";

export const modalEventHooks = {
  open: createEventHook<ModalOpenPayload>(),
  close: createEventHook<ModalClosePayload>(),
};

interface ModalOpenPayload {
  id: AppModalId;
  binding?: Record<string, unknown>;
}

interface ModalClosePayload {
  id: AppModalId;
  confirmed?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

export interface ModalOpenOptions<T> {
  onConfirm?: (payload: T) => void;
  onCancel?: () => void;
}

const { allowUserScroll, preventUserScroll } = useScrollPrevent();

export const useModal = (id: AppModalId) => {
  return {
    open<T>(binding?: Record<string, unknown>, options?: ModalOpenOptions<T>) {
      const { off } = modalEventHooks.close.on((event) => {
        if (event.id !== id) return;

        off();

        if (event.confirmed) {
          options?.onConfirm?.(event.payload);
        } else {
          options?.onCancel?.();
        }
      });

      preventUserScroll();
      modalEventHooks.open.trigger({ id, binding });
    },
    close(options?: Omit<ModalClosePayload, "id">) {
      allowUserScroll();
      modalEventHooks.close.trigger({
        id,
        ...options,
      });
    },
  };
};
